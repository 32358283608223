 .button-container {
     float: right;
     margin-bottom: 20px;
 }

 .title {
     color: #707070;
     font-weight: 700;
     font-size: 25px;
 }

 .save-btn {
     text-align: center;
     margin-top: 20px;
 }

 .wrapper-class {
    //  border-top: 1px solid #ccc;
    //  border-bottom: 1px solid #ccc;
     margin: 10px 0;
     min-height: 170px;
 }

 .editor-class {
     min-height: 170px;
     padding: 5px;
     border: 1px solid #ccc;
     margin: 0 !important;
 }