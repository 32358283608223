@import '../../../public/assets/style/variables.scss';

.referall_table{
    padding-top: 25px;
}

.close_outlined{
    color: $error_color;
    cursor: pointer;
}

.check_outlined{
color: $menu_iteam_color;

}


.ref_res_list_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px ;
    align-items: center
}


.ref_res_title-text{
    @include dashboard_font_text;
   font-size:25px;
   margin-bottom: 0px !important;
   
}

.is_disabled td {background:#ffd6d6 !important;}
