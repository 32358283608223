@import "../../../public/assets/style/variables.scss";

.reseller-top_title {
  color: #707070;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 0;
}

.reseller-page_add-edit-btn {
  float: right;
  margin-bottom: 15px;
}

.is_disabled td {
  background: #ffd6d6 !important;
}

.activation-drawer {
  display: flex;
  justify-content: center;
}

.reseller-page-container {
  position: relative;
}

.reseller-info-drawer-list {
  list-style: none;
  color: #707070;
  font-weight: 700;
  font-size: 0.8rem;
  font-size: 16px;
  padding: 15px 30px;
  gap: 20px;
  width: 100%;
  box-shadow: 4px 4px 12px #0000001c;
  border-radius: 10px;
}

.reseller-infor-drawer-title {
  @include dashboard_font_text;
  font-size: 20px;
  margin: 20px 0px;
}

.reseller-item-row {
  cursor: pointer;
}
