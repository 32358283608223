@import '../../../public/assets/style/variables.scss';

.ReferralEarnings_title-text{
    @include dashboard_font_text;
    font-size:25px
  
  }

  .ReferralEarnings_button{
    // height: 28px !important;
    // font-size: 13px;
    // padding: auto;
    // // padding-top: 14px !important;
    // line-height: 0px !important;
    // text-align: center !important;
    // border-radius: 3px;
    // width: 80px;
   
  }

  .ReferralEarnings_button_div{
    display: flex;
    justify-content: space-between;
    width: 170px;
  
  }