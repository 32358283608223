.profile_dropdown {
  min-width: 150px;
  left: 12px;
  top: 0px;
  padding-bottom: 0px;
}

.profile_img {
  height: auto;
  margin-left: 2px;
  margin-right: 10px;
  object-fit: contain;
}

:global .ant-space-item {
  margin-left: -7px !important;
}


// ant-dropdown-menu-item ant-dropdown-menu-item-active
.profile_name {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
