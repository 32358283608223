@import '../../../public/assets/style/variables.scss';

.referral-top_title{
    color: #707070;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 0;
  }

  .referral-page_add-edit-btn{
    float: right;
    margin-bottom: 15px;
  }

  .referral-page-container{
    position: relative;
  }

  .is_disabled td {background:#ffd6d6 !important;}