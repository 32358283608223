.send_message_input {
  border-radius: 5px;
  width: 100%;
  padding: 3px 40px 3px 20px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px !important;
  position: relative;
  resize: none;
  height: 100px;
}

.send_message_input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}


.send_message_form {
  position: relative;
}

.send_message_form_none {
  height: 0px;
  overflow: hidden;
  position: relative;
}

.send_message_button {
  font-size: 20px;
  cursor: pointer;
  transform: rotate(270deg);
  position: absolute;
  top: 37%;
  right: 10px;
}
