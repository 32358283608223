@import "../index.module.scss";

.server_statistics_div {
  padding: 32px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -11.6375px 37.3625px rgba(0, 0, 0, 0.03);
  border-radius: 4.9px;
  width: 100%;
  height: 100%;

  .server_statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
}

.server-txt {
  color: #272727;
  font-weight: 600;
  margin-bottom: 0;

  &_item {
    color: #959595;
  }
}

.device_progres {
  width: 74%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 12px #0000001c;
  border-radius: 10px;
  height: 300px;
  padding: 0px 20px;
  position: relative;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.used_devices_progres {
  height: 230px;
  width: 90%;
}

.device_text {
  position: absolute;
  top: 10px;
  left: 20px;
  @include dashboard_font_text;
}

.server_container {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-bottom: 30px;

  .server-text-style {
    width: 3px;
    height: 33px;
    background: #ed5b75;
    border-radius: 2.45px;
  }
}

@media (max-width: 1195px) {
  .device_progres {
    width: 58%;
  }
}

@media (max-width: 1413px) {
  .server_statistics_div {
    padding: 31px 25px;
  }
}

@media (max-width: 767px) {
  .device_progres {
    width: 100%;
    padding: 25px 0px;
  }
}