@import '../../../public/assets/style/variables.scss';

.devices_head_text{
    @include dashboard_font_text;
    font-size: 25px;

}

.close_outlined{
    color: $error_color;
}

.check_CircleFilled{
    color: $menu_iteam_color;
}

//  .ant-pagination-options-size-changer {
//     width: 103px!important;
//     margin-top: -14px !important;
//   }

//   :global .ant-select-arrow{
//     margin-top: 3px;
    
// }



.deviceManager_row{
    cursor: pointer;
}