@import "../index.module.scss";

.userInfo_container {
  display: flex;
  padding: 15px 30px;
  gap: 20px;
  width: 100%;
  box-shadow: 4px 4px 12px #0000001c;
  border-radius: 10px;

  ul {
    list-style: none;

    li {
      counter-increment: steps;
      list-style: none;
    }
  }

  ol {
    li {
      &::before {
        background: darkorchid;
      }
    }
  }
}

.user-info_item {
  @include dashboard_font_text;
  font-size: 16px;
}

.userInfo_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userInfo_list {
  height: 280px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.device-payments-title {
  color: #707070;
  font-weight: 700;
  font-size: 25px;
}

@media screen and (max-width:880px) {
  .user-info_item {
    font-size: 10px !important;
  }
}


@media screen and (max-width:730px) {
  .userInfo_list {
    display: block;
    height: auto;

    .user-info_item {
      margin: 10px 0;
    }
  }
}