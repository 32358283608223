@import '../../../public/assets/style/variables.scss';

.blacklist_list_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px ;
    align-items: center
}

.blacklist_title-text{
    @include dashboard_font_text;
    margin-bottom: 0px !important;
    font-size:25px;
}

// .blacklist_add{
//     position: fixed;
//     // right: 20px;
// }