.pro-widget-warning-text {
  background-color: rgba(255, 229, 100, 0.3);
  border-left-color: #ffe564;
  border-left-width: 9px;
  border-left-style: solid;
  padding: 20px 45px 20px 26px;
  margin-bottom: 30px;
}

    
  .pro-widget-tab-layer {
    background-color: rgba(229, 229, 229, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 99;
  }

.google-ima-title {
  font-size: 28px;
  font-weight: 600;
}

.google-ima_tag-url-container {
  display: flex;
  gap: 10px;
  align-items: center;

  &_url-inp {
    width: 400px;
  }

  &_test-url-btn {
    margin-top: 6.5px;
  }
}

.google-ima_save-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.google-ima_number-inp {
  width: 70px;
  outline: none;
  border: 1px solid;
  border-radius: 4px;
  margin: 0px 10px 10px 0;

  &:disabled {
    cursor: not-allowed;
  }
}
