@import '../../../public/assets/style/variables.scss';

.PaymentHistory_list_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.PaymentHistory_title-text{
    @include dashboard_font_text;
    font-size:25px;
}