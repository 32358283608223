@import "../../../../public/assets/style/variables.scss";

.app_info {
  padding: 15px, 20px;
  display: flex;
  flex-direction: column;
}


.uload_button {
  margin-top: 15px;
}

.app_info_title {
  @include dashboard_font_text;
  font-size: 25px;
}

.app_info_form {
  // width: 100%;
  // display: flex;
  // justify-content: space-between;
}

.form_item_currencies {
  .select {
    width: 100% !important;
    padding: 0px !important;
  }

  width: 100% !important;
  margin: 0% auto;
}

.app_info_inputs {
  width: 100%;

}

.color-picker {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.branding_save {
  width: 350px;
  margin-bottom: 25px !important;
}

.advanced_settings_link {
  display: flex;
  justify-content: center;
  color: rgb(9, 169, 243);
  font-size: 15px;
  text-align: center;
  text-decoration: underline;
}

.branding_upload_img {
  width: 100px !important;
  height: 100px !important;
  object-fit: contain !important;
  border-radius: 15px !important;
  ;
}


.branding__text-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // gap: 50px;

  .inputs-col {
    width: 350px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 1192px) {
  .branding__text-form {

    .inputs-col {
      width: 290px  !important;
    }
  }
  .branding_save{
    width: 290px !important;
  }
}