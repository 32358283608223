@import '../index.module.scss';


.playlists_list_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center
    
}

.playlists_title-text{
    @include dashboard_font_text;
    font-size:25px;
    margin: 0px;
}

.ref-link {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width:880px) {
    .playlists_title-text{
        font-size:19px !important;
    }
  }