.search_container {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.search_input {
  width: 100%;
  margin-bottom: 10px;
  height: 35px;
  border-radius: 5px !important;
  border: none;
}

.search_input_icon {
  font-size: 20px;
}
