.reseller-traslaton-section {
    width: 50%;
    padding-bottom: 30px;
}

.tarnslation-container {
    margin: 10px 0;
}

.reseller-traslaton-section-title {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;

    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 0;
    }
}

.label {
    color: gray;
    margin-bottom: 5px;
}

@media screen and (max-width: 500px) {
    .reseller-traslaton-section {
        width: 100%;
    }
}
