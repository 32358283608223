.messenger_container {
  width: 100%;
  background-size: 100px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.messenger_container_active {
  width: 100%;
  height: 100%;
  background-size: 100px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}
