@import '../index.module.scss';

// .package_list_table{
//     // min-height: 834px;   
// }


.package_list_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px ;
    align-items: center
}


.package_title-text{
    @include dashboard_font_text;
    margin-bottom: 0px !important;
   font-size:25px;
}

.checkbox_div{
    display: flex;
    
   
}

.save_button_div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.save_button{
    width: 150px;
}


.checkbox_yearly{
    margin-bottom: -5px !important;
}

.package_add{
    position: fixed;
    right: 20px;
}
