.message_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 15px;
  color: #262626;
}

.message_description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 20px 0px;
  color: rgba(0, 0, 0, 0.45);
}

.message_date_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.message_date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.message_email {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.save_button_div_hidden {
  display: none;
}

.answer_text {
  align-items: flex-start;
  padding: 5px 12px;
  // width: 1341px;
  height: 143px;
  background: #fafafa;
  border-radius: 2px;
  margin: 0% auto;
}
