@import '../../../public/assets/style/variables.scss';


.languages_row{
    cursor: pointer;
}

.Languages_head_text{
    @include dashboard_font_text;
   font-size:25px;
}