@import '../../../public/assets/style/variables.scss';

.server_list_title{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
   margin-bottom: 10px

   
}


.server_title-text{
    @include dashboard_font_text;
   font-size:25px;
}

.checkbox_div{
    display: flex;
    
   
}

.checkbox_yearly{
    margin-bottom: -5px !important;
   
}