@import '../index.module.scss';


// .status_table{
//     margin-top: 25px;
// }

.status_table_head{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px ;
    align-items: center
}

.status_head_text{
    @include dashboard_font_text;
    margin-bottom: 0px !important;
    font-size:25px;
}

// .status_add{
//     position: absolute;
//     right: 20px;
//     top:5px;
// }

.save_button_div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.save_button{
    width: 150px;
}