@import "../../../public/assets/style/variables.scss";

.notification-page_title {
  @include dashboard_font_text();
  font-size: 25px;
  margin-bottom: 0;
}

.notification-page-container{
  position: relative;
}

.start-to-end {
  width: 100%;
  margin-bottom: 30px;
}

.notification-drawer_datepicker {
  display: block;
  margin: 10px 0;
}

.notification-drawer__save-button {
  display: flex;
  justify-content: center;
}

.freeze {
  td {
    &:hover {
      background-color: rgba(180, 180, 180, 0.2);
    }
  }
  background-color: rgba(180, 180, 180, 0.2);
}
