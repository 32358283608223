
.checkbox_div{
    display: flex;
    
   
}

.save_button_div{
    width: 100%;
    display: flex;
}

.save_button{
    width: 250px;
}

.smpt_form{
    width: 250px;
    // margin: 0% auto;
    // border-radius: 20px;
    // box-shadow: 6px 6px 12px #0000001C;
    // padding: 15px 20px;
}
