/* 
*{
  font-size: 10px;
} */

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

#root {
    height: 100%;
}

.ant-switch-checked {
    background-color: #1890ff !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.language-block .ant-select-selector {
    width: 50px !important;
}

.full-screen-loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;

    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.675);
    background-color: hsla(249, 6%, 42%, 0.562);
    display: flex;
    justify-content: center;
    padding: 30px;
}
